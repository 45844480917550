import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql }  from 'gatsby'
import compose from 'lodash/fp/flowRight'
import UnderstandTheDifference from '../components/UnderstandTheDifference'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
// import Column from '../components/Column'
import Div from '../components/Div'
import H4 from '../components/H4'
import H6 from '../components/H6'
import HDAparicoes from '../components/ClipagemHeader'
import Tabs from '../components/TabClipagem2'
import { Container } from '../components/styles/Layout.styles'
import { withSolutions } from '../components/SolutionProvider'
import EleveSeuPotencial from '../images/clipagem/clipagem.png'
import { 
  faqCloudServer,
  imgClipagem
} from '../components/styles/ProductCloud.styles'
import { titleForm } from '../components/styles/InternalPage.styles'
const initialModals = {
  support: false,
  moreInfo: false
}

export const PageTemplate = ({
  data,
  ...rest
}) => {
  const [modals, setModals] = useState(initialModals)

  const setModalVisibility = modal => visible => {
    setModals({
      ...modals,
      [modal]: visible
    })
  }
  return (
    <Fragment>
      <section>
        <Container>
          <Row
            mt={[40, 70]}
            justifyContent={['left', 'center']}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <H4
              color='black'
              variant='highEmphasis'
              css={titleForm}
              maxWidth={['90%', 370]}
              style={{
                textAlign: 'center',
                marginBottom: 8
              }}
            >
              Estão falando sobre a HostDime
            </H4>
            <Div
              maxWidth={['90%', 328]}
              style={{
                textAlign: 'center'
              }}
            >
              <H6 style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Aparições na mídia</H6>
            </Div>
          </Row>
          <Div>
            <HDAparicoes />
          </Div>
        </Container>
      </section>
      <section css={faqCloudServer}>
        <Container>
          <Row
            mt={[40, 70]}
            mb={[50]}
            justifyContent='center'
            alignItems={'center'}
            flexDirection={'column'}
          >
            <Div
              alignItems={'center'}
              style={{
                marginBottom: 30
              }}
            >
              <img css={imgClipagem} src={EleveSeuPotencial} alt='Cloud Server HostDime' />
            </Div>
            <H4
              color='black'
              variant='highEmphasis'
              css={titleForm}
              style={{
                marginBottom: 16
              }}
            >
              Clipagem
            </H4>
            <Div
              maxWidth={['90%', 248]}
              style={{
                textAlign: 'center'
              }}
            >
              <H6 style={{ color: 'rgba(0, 0, 0, 0.6)' }}>Confira nossas aparições na Mídia.</H6>
            </Div>
          </Row>
          <Row
            mb={[40, 70]}
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
          >
            <Tabs />
          </Row>
        </Container>
      </section>
      <UnderstandTheDifference
        visible={modals.moreInfo}
        onClose={() => setModalVisibility('moreInfo')(false)}
      />
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo 
        title='Clipagem'
        description='Proteja seus dados de problemas técnicos e ataques virtuais com o Cloud Backup da HostDime. O melhor custo-benefício e a maior segurança.'
      />
      <PageTemplate
        title='Clipagem'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-backup/cloud_backup_hostdime@4x.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const enhance = compose(
  withSolutions
)

export default enhance(StaticPage)
