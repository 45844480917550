import React, { Fragment } from 'react';
import 'rc-collapse/assets/index.css';
import Collapse, { Panel } from 'rc-collapse'
import Column from '../components/Column'
import Div from '../components/Div'
import {
  clipagemContentItem,
  clipagemItem
} from '../components/styles/Clipagem.styles'

const App = ({
  date
}) => (
  <Fragment>
    <Column
      mb={[16]}
    >
      <Div
        maxWidth={['100%', '100%', 422]}
      >
        <Collapse accordion>
          <Panel header='JANEIRO' css={clipagemItem}>
            <div css={clipagemContentItem}>
              <h4>21/01</h4>
              <h3>Certificações ISO 27000 e 20001</h3>
              <a href='https://veja2.abril.com.br/abril_releases/fortalecendo-mercado-de-tecnologia-no-nordeste-data-center-conquista-certificacao-iso-27001-e-20000/' target='_blank'>Veja</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>21/01</h4>
              <h3>Certificações ISO 27000 e 20001</h3>
              <a href='https://www.terra.com.br/noticias/dino/fortalecendo-mercado-de-tecnologia-no-nordeste-data-center-conquista-certificacao-iso-27001-e-20000,ebe6a8a1614dfec4ef9b6b3825517d0dy29xciss.html' target='_blank'>Terra</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>21/01</h4>
              <h3>Certificações ISO 27000 e 20001</h3>
              <a href='https://www.mundodomarketing.com.br/noticias-corporativas/conteudo/227329/fortalecendo-mercado-de-tecnologia-no-nordeste-data-center-conquista-certificacao-iso-27001-e-20000' target='_blank'>Mundo Marketing</a>
            </div>
          </Panel>
          <Panel header='FEVEREIRO' css={clipagemItem}>
            <div css={clipagemContentItem}>
              <h4>21/02</h4>
              <h3>Construção data center Bogotá</h3>
              <a href='https://www.terra.com.br/noticias/dino/gigante-do-mercado-de-tecnologia-constroi-novo-data-center-tier-iv-em-bogota-na-colombia,7425e058d2cb32294ea17ea7832015605112a0ws.html' target='_blank'>Terra</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>21/02</h4>
              <h3>Construção data center Bogotá</h3>
              <a href='https://www.mundodomarketing.com.br/noticias-corporativas/conteudo/231244/gigante-do-mercado-de-tecnologia-constroi-novo-data-center-tier-iv-em-bogota-na-colombia' target='_blank'>Mundo Marketing</a>
            </div>
          </Panel>
          <Panel header='MARÇO' css={clipagemItem}>
            <div css={clipagemContentItem}>
              <h4>29/03</h4>
              <h3>Matéria sobre cloud server ser ferramenta aliada ao isolamento em tempos de pandemia</h3>
              <a href='https://auniao.pb.gov.br/servicos/arquivo-digital/jornal-a-uniao/2020/marco/a-uniao-29-03.2020/view' target='_blank'>A União</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>29/03</h4>
              <h3>Matéria sobre isolamento e cloud server</h3>
              <a href='https://paraiba.pb.gov.br/diretas/secretaria-da-educacao-e-da-ciencia-e-tecnologia/horizontes-da-inovacao/noticias/isolamento-social-revela-as-vantagens-do-home-office' target='_blank'>Portal de Notícias do Governo da Paraíba</a>
            </div>
          </Panel>
          <Panel header='ABRIL' css={clipagemItem}>
            <div css={clipagemContentItem}>
              <h4>07/04</h4>
              <h3>Computação em nuvem: maior aliada no home office</h3>
              <a href='https://administradores.com.br/noticias/computa%C3%A7%C3%A3o-em-nuvem-maior-aliada-no-home-office' target='_blank'>Administradores.com.br</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>16/04</h4>
              <h3>Com dólar em alta, contratar serviços de TI em real é melhor opção</h3>
              <a href='https://administradores.com.br/noticias/com-d%C3%B3lar-em-alta-contratar-servi%C3%A7os-de-ti-em-real-%C3%A9-melhor-op%C3%A7%C3%A3oS' target='_blank'>Administradores.com.br</a>
            </div>
          </Panel>
          <Panel header='MAIO' css={clipagemItem}>
            <div css={clipagemContentItem}>
              <h4>13/05</h4>
              <h3>LGPD: porque a sua empresa deve manter o processo de conformidade durante a crise do novo coronavírus</h3>
              <a href='https://administradores.com.br/noticias/lgpd-porque-a-sua-empresa-deve-manter-o-processo-de-conformidade-durante-a-crise-do-novo-coronav%C3%ADrus' target='_blank'>Administradores.com.br</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>21/05</h4>
              <h3>Soluções de computação em nuvem são aliadas para gestão de redes de franquias</h3>
              <a href='https://administradores.com.br/noticias/solu%C3%A7%C3%B5es-de-computa%C3%A7%C3%A3o-em-nuvem-s%C3%A3o-aliadas-para-gest%C3%A3o-de-redes-de-franquias' target='_blank'>Administradores.com.br</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>22/05</h4>
              <h3>João Pessoa se firma no mercado de tecnologia com o desenvolvimento de soluções personalizadas em nuvem</h3>
              <a href='https://www.terra.com.br/noticias/dino/joao-pessoa-se-firma-no-mercado-de-tecnologia-com-o-desenvolvimento-de-solucoes-personalizadas-em-nuvem,8ac034f3f104a2f0b436031c78b2fc0cuhr64wxb.html' target='_blank'>Terra</a>
              <a href='https://www.mundodomarketing.com.br/noticias-corporativas/conteudo/237992/joao-pessoa-se-firma-no-mercado-de-tecnologia-com-o-desenvolvimento-de-solucoes-personalizadas-em-nuvem' target='_blank'>Mundo do Marketing</a>
              <a href='http://portal.comunique-se.com.br/joao-pessoa-se-firma-no-mercado-de-tecnologia-com-o-desenvolvimento-de-solucoes-personalizadas-em-nuvem-237992/' target='_blank'>Comunique-se</a>
              <a href='http://www.agenciaoglobo.com.br/dinonews/Default.aspx?idnot=80630&tit=Jo%c3%a3o+Pessoa+se+firma+no+mercado+de+tecnologia+com+o+desenvolvimento+de+solu%c3%a7%c3%b5es+personalizadas+em+nuvem' target='_blank'>Agência O Globo</a>
            </div>
          </Panel>
        </Collapse>
      </Div>
    </Column>
    <Column
      mb={[16]}
    >
      <Div
        maxWidth={['100%', '100%', 422]}
      >
        <Collapse accordion>
          <Panel header='JULHO' css={clipagemItem}>
            <div css={clipagemContentItem}>
              <h3>HostDime Brasil no 1º São João digital do Nordeste</h3>
              <a href='https://www.youtube.com/watch?v=9riPLCCi-bk' target='_blank'>Youtube</a>
            </div>
            <div css={clipagemContentItem}>
              <h3>Ascenty is the ISG Provider Lens™ Colocation Quadrant leader in Brazil, Ascenty</h3>
              <a href='https://www.ascenty.com/en/artigo/ascenty-is-the-isg-provider-lens-colocation-quadrant-leader-in-brazil' target='_blank'>Ascenty</a>
            </div>
          </Panel>
          <Panel header='AGOSTO' css={clipagemItem}>
            <div css={clipagemContentItem}>
              <h4>31/08</h4>
              <h3>Data Center paraibano é o primeiro a receber certificação TIER III na região</h3>
              <a href='https://administradores.com.br/noticias/data-center-paraibano-e-o-primeiro-a-receber-certificacao-tier-iii-na-regiao' target='_blank'>Administradores.com.br</a>
            </div>
          </Panel>
          <Panel header='SETEMBRO' css={clipagemItem}>
            <div css={clipagemContentItem}>
              <h3>Data Center paraibano é o primeiro a receber certificação TIER III na região </h3>
              <a href='https://www.terra.com.br/noticias/dino/data-center-paraibano-e-o-primeiro-a-receber-certificacao-tier-iii-na-regiao,c1d6767bbeb87f3c60cb3a6dbc52123aut1aa3ic.html' target='_blank'>Terra</a>
              <a href='https://www.clickpb.com.br/paraiba/empresa-paraibana-recebe-certificacao-internacional-de-excelencia-em-infraestrutura-e-seguranca-de-dados-290812.html' target='_blank'>Portal ClickPB</a>
              <a href='http://tibahia.com/telecom-e-datacenter/data-center-paraibano-e-o-primeiro-a-receber-certificacao-tier-iii-na-regiao/' target='_blank'>Portal TI Bahia</a>
              <a href='https://www.baguete.com.br/noticias/02/09/2020/hostdime-conquista-certificacao-tier-iii' target='_blank'>Portal Baguete</a>
              <a href='https://www.datacenterdynamics.com/br/not%C3%ADcias/data-center-da-hostdime-conquista-selo-tier-iii-do-uptime-institute/' target='_blank'>Data Center Dynamics</a>
              <a href='http://www.paraibatotal.com.br/noticias/2020/09/02/53556-data-center-paraibano-e-o-primeiro-a-receber-certificacao-tier-iii-na-regiao' target='_blank'>Paraíba Total</a>
              <a href='https://negociosemfoco.com/amp/?title=data-center-paraibano-e-o-primeiro-a-receber-certificacao-tier-iii-na-regiao&partnerid=1441&releaseid=245831' target='_blank'>Negócios em Foco</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>08/09</h4>
              <h3>HostDime Brazil Achieves Tier III Certification From Uptime Institute</h3>
              <a href='https://finance.yahoo.com/news/hostdime-brazil-achieves-tier-iii-140000943.html' target='_blank'>Yahoo! Finance</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>08/09</h4>
              <h3>HostDime Brazil Achieves Tier III Certification From Uptime Institute</h3>
              <a href='https://www.newswire.com/news/hostdime-brazil-achieves-tier-iii-certification-from-uptime-institute-21207722?_ga=2.165786878.1199488596.1599573678-1447313862.1599082463' target='_blank'>NewsWire</a>
            </div>
          </Panel>
          <Panel header='DEZEMBRO' css={clipagemItem}>
            <div css={clipagemContentItem}>
              <h4>10/12</h4>
              <h3>Segurança digital: empresa paraibana recebe certificação inédita no Brasil</h3>
              <a href='https://paraibamaster.com.br/2020/12/10/seguranca-digital-empresa-paraibana-recebe-certificacao-inedita-no-brasil/' target='_blank'>Paraíba Master</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>10/12</h4>
              <h3>Empresa paraibana obtém certificação inédita no Brasil em segurança digital</h3>
              <a href='https://www.clickpb.com.br/paraiba/empresa-paraibana-obtem-certificacao-inedita-no-brasil-em-seguranca-digital-297453.html' target='_blank'>Clickpb</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>11/12</h4>
              <h3>HostDime conquista ISO 27701</h3>
              <a href='https://www.baguete.com.br/noticias/11/12/2020/hostdime-conquista-iso-27701' target='_blank'>Baguete</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>11/12</h4>
              <h3>Conheça a ISO da LGPD e a primeira empresa certificada no Brasil</h3>
              <a href='https://www.segs.com.br/info-ti/266895-conheca-a-iso-da-lgpd-e-a-primeira-empresa-certificada-no-brasil' target='_blank'>Segs</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>11/12</h4>
              <h3>- Empresa de computação em nuvem obtém 1ª certificação em conformidade com a LGPD</h3>
              <a href='https://www.pontoisp.com.br/empresa-de-computacao-em-nuvem-obtem-1a-certificacao-em-conformidade-com-a-lgpd/' target='_blank'>Ponto ISP</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>11/12</h4>
              <h3>Certificação em conformidade com a LGPD é emitida para a primeira empresa no Brasil</h3>
              <a href='https://inforchannel.com.br/certificacao-em-conformidade-com-a-lgpd-e-emitida-para-a-primeira-empresa-no-brasil/' target='_blank'>Inforchannel</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>13/12</h4>
              <h3>Certificação em conformidade com a LGPD é emitida para a primeira empresa no Brasil</h3>
              <a href='http://tibahia.com/telecom-e-datacenter/certificacao-em-conformidade-com-a-lgpd-e-emitida-para-a-primeira-empresa-no-brasil/' target='_blank'>TI bahia</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>14/12</h4>
              <h3>HostDime conquista primeiro certificado ISO 27701 emitido no Brasil</h3>
              <a href='https://www.datacenterdynamics.com/br/not%C3%ADcias/certifica%C3%A7%C3%A3o-em-conformidade-com-a-lgpd-%C3%A9-emitida-para-a-primeira-empresa-no-brasil/' target='_blank'>DCD</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>17/12</h4>
              <h3>Empresa paraibana obtém certificação inédita no Brasil</h3>
              <a href='http://fmrural.com.br/2020/12/17/empresa-paraibana-obtem-certificacao-inedita-no-brasil/' target='_blank'>Fm Rura</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>17/12</h4>
              <h3>Primeira empresa brasileira é certificada com a ISO da LGPD</h3>
              <a href='https://docmanagement.com.br/12/17/2020/primeira-empresa-brasileira-e-certificada-com-a-iso-da-lgpd/' target='_blank'>Docmanagement</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>18/12</h4>
              <h3>Empresa paraibana, HostDime obtém certificação inédita no Brasil</h3>
              <a href='https://www.portaltvcariri.com.br/empresa-paraibana-hostdime-obtem-certificacao-inedita/' target='_blank'>Portal tv Cariri</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>18/12</h4>
              <h3>Novos caminhos para a proteção de dados na saúde,</h3>
              <a href='https://saudedigitalnews.com.br/18/12/2020/novos-caminhos-para-a-protecao-de-dados-na-saude/' target='_blank'>Saúde digital news</a>
            </div>
          </Panel>
        </Collapse>
      </Div>
    </Column>
  </Fragment>
  
);

export default App