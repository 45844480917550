import React from 'react'
import { Tabs, TabLink, TabContent } from 'react-tabs-redux'
import Content2019 from '../components/Clipagem2019'
import Content2020 from '../components/Clipagem2020'

const Tab2 = () => (
  <Tabs
    onChange={tab => console.log(`Tab selected: ${tab}`)} // eslint-disable-line no-console
  >
    <div style={{ marginBottom: 30, textAlign: 'center' }}>
      <TabLink to="tab1" style={{ marginRight: 8 }}>2019</TabLink>
      <TabLink to="tab2" default>2020</TabLink>
    </div>
    <div>
      <TabContent for="tab1">
        <Content2019 />
      </TabContent>
      <TabContent for="tab2">
        <Content2020 />
      </TabContent>
    </div>
  </Tabs>
);

export default Tab2