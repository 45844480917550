import React, { Component, Fragment } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Row from '../components/Row'
import Div from '../components/Div'
import Column from '../components/Column'
import Body2 from '../components/Body2'
import Bg from '../images/clipagem/Brasil.png'
// import Bg2 from '../images/clipagem/Dedicado.png'
// import Bg3 from '../images/clipagem/TIERIII.png'
import PersonPin from '@material-ui/icons/OpenInNew'

import { 
  imgItem,
  titleContent,
  linkStyle
} from '../components/styles/Clipagem.styles'

const HeaderItens = ({
  imageItem,
  title,
  author,
  linkTo,
  textLink
}) => {
  return (
    <Row
      mt={[30, 50]}
      style={{
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 12
      }}
    >
      <Div
        flexDirection={['column', 'column', 'row']}
        maxWidth={['100%', 422, 680, 1032]}
        style={{
          margin: '0 auto',
          textAlign: 'center',
          padding: 0,
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: 12
        }}
      >
        <Column
          width={[1, 1, 1/2]}
          px={null}
        >
          <Div
            maxWidth={['100%', 422, 680, 813]}
            css={imgItem}
          >
            <img src={imageItem} alt='' />
          </Div>
        </Column>
        <Column
          px={null}
          width={[1, 1, 1/2]}
          pt={[40, 40, 42, 42]}
          pb={[40, 40, 42, 42]}
          pl={[16, 16, 42, 42]}
          pr={[16, 16, 42, 42]}
          css={titleContent}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Div
            style={{
              fontSize: 11.8,
              fontWeight: 600,
              lineHeight: 1.36,
              letterSpacing: 2,
              color: '#ff5800',
              marginBottom: 8
            }}
          >
            {author}
          </Div>
          <Body2
            style={{
              fontSize: 24,
              fontWeight: 600,
              lineHeight: 1.31,
              color: '#212121',
              marginBottom: 20
            }}
          >
            {title}
          </Body2>
          <a
            href={linkTo}
            css={linkStyle}
            style={{
              fontSize: 11.8,
              fontWeight: 600,
              lineHeight: 1.36,
              letterSpacing: 0.4,
              color: '#878694',
              marginBottom: 8
            }}
          >
            {textLink} 
            <PersonPin 
              style={{
                color: 'rgba(0, 0, 0, 0.54)'
              }}
            />
          </a>
        </Column>
      </Div>
    </Row>
  )
}

export default class SimpleSlider extends Component {
  render() {
    var settings = {
      className: "slider variable-width",
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      outline: 'none',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1, 
            slidesToScroll: 1,
            arrows: false,
          }
        }
      ]
    }
    return (
      <Fragment>
        <div style={{ marginBottom: 80 }}>
          <Slider {...settings}>
            <HeaderItens
              imageItem={Bg}
              // author={'2019'}
              title={'HostDime é destaque no amistoso Brasil x Nigeria'}
              textLink={'Link da publicação'}
              linkTo={'https://www.cbf.com.br/cbf-tv/confira-as-imagens-do-empate-em-1-a-1-entre-brasil-e-nigeria'}
            />
          </Slider>
        </div>
      </Fragment>
    )
  }
}