import React, { Fragment } from 'react';
import 'rc-collapse/assets/index.css';
import Collapse, { Panel } from 'rc-collapse'
import Column from '../components/Column'
import Div from '../components/Div'
import {
  clipagemContentItem,
  clipagemItem
} from '../components/styles/Clipagem.styles'

const App = ({
  date
}) => (
  <Fragment>
    <Column
      mb={[16]}
    >
      <Div
        maxWidth={['100%', '100%', 422]}
      >
        <Collapse accordion>
          <Panel header='FEVEREIRO' css={clipagemItem}>
            <div css={clipagemContentItem}>
              <h4>18/02</h4>
              <h3>Data Centers no Nordeste</h3>
              <a href='https://www.terra.com.br/noticias/dino/data-centers-no-nordeste-surgem-como-alternativa-para-o-mercado-de-cloud-computing,f34d2c1bbad06a8b22449723c7e2aad5un420wcj.html' target='_blank'>Terra</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>18/2</h4>
              <h3>Data Centers no Nordeste</h3>
              <a href='https://www.mundodomarketing.com.br/noticias-corporativas/conteudo/199020/data-centers-no-nordeste-surgem-como-alternativa-para-o-mercado-de-cloud-computing' target='_blank'>Mundo Marketing</a>
            </div>
          </Panel>
          <Panel header='MARÇO' css={clipagemItem}>
            <div css={clipagemContentItem}>
              <h4>31/03</h4>
              <h3>Nota sobre o Security Fórum na coluna do Íuri Moreira</h3>
              <a href='https://auniao.pb.gov.br/servicos/arquivo-digital/jornal-a-uniao/2019/marco/jornal-em-pdf-31-03-19.pdf/view' target='_blank'>Íuri Moreira</a>
            </div>
          </Panel>
          <Panel header='ABRIL' css={clipagemItem}>
            <div css={clipagemContentItem}>
              <h4>01/04</h4>
              <h3>Matéria sobre Security Fórum</h3>
              <a href='https://portalcorreio.com.br/forum-em-jp-discute-seguranca-de-dados-na-internet/' target='_blank'>Portal Correio</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>02/04</h4>
              <h3>Matéria sobre o Security Fórum</h3>
              <a href='https://g1.globo.com/pb/paraiba/noticia/2019/04/02/forum-sobre-seguranca-de-dados-na-internet-acontece-em-joao-pessoa-inscricoes-sao-gratuitas.ghtml' target='_blank'>G1 Paraíba</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>02/04</h4>
              <h3>Matéria sobre o Security Fórum</h3>
              <a href='https://www.pbagora.com.br/noticia/Policial/20190402064052/forum-discute-hoje-na-capital-a-seguranca-de-dados-na-internet' target='_blank'>PB Agora</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>02/04</h4>
              <h3>Matéria sobre o Security Fórum</h3>
              <a href='https://brejo.com/2019/04/02/em-joao-pessoa-acontece-o-forum-sobre-seguranca-de-dados-na-internet/' target='_blank'>Brejo.com</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>02/04</h4>
              <h3>Matéria sobre o Security Fórum</h3>
              <a href='http://www.araruna1.com/noticias/20/forum-discute-hoje-na-capital-a-seguranca-de-dados-na-internet.html' target='_blank'>Araruna1</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>02/04</h4>
              <h3>Matéria sobre o Security Fórum</h3>
              <a href='https://auniao.pb.gov.br/servicos/arquivo-digital/jornal-a-uniao/2019/abril/jornal-em-pdf-02-04-19-1.pdf/view' target='_blank'>A União</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>25/04</h4>
              <h3>Marcelo Barbosa, Desenvolvedor de negócios da HostDime Brasil, no Bom Dia Paraíba Tema: cuidados com dados pessoais na internet</h3>
              <a href='http://g1.globo.com/pb/paraiba/bom-dia-pb/videos/t/edicoes/v/especialista-explica-os-cuidados-necessarios-com-seus-dados-pessoais-na-internet/7567313/' target='_blank'>Bom Dia Paraíba</a>
            </div>
          </Panel>
          <Panel header='MAIO' css={clipagemItem}>
            <div css={clipagemContentItem}>
              <h4>29/05</h4>
              <h3>Plataformas autogerenciáveis na nuvem</h3>
              <a href='https://exame.abril.com.br/negocios/dino_old/plataformas-autogerenciaveis-facilitam-a-entrada-de-empresas-na-nuvem/' target='_blank'>Exame</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>29/05</h4>
              <h3>Plataformas autogerenciáveis na nuvem</h3>
              <a href='https://www.terra.com.br/noticias/dino/plataformas-autogerenciaveis-facilitam-a-entrada-de-empresas-na-nuvem,dc5c6fa3661b27197432f7e30b02fb66dor7pgq8.html' target='_blank'>Terra</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>29/05</h4>
              <h3>Plataformas autogerenciáveis na nuvem</h3>
              <a href='https://www.mundodomarketing.com.br/noticias-corporativas/conteudo/208654/plataformas-autogerenciaveis-facilitam-a-entrada-de-empresas-na-nuvem' target='_blank'>Mundo Marketing</a>
            </div>
          </Panel>
          <Panel header='JUNHO' css={clipagemItem}>
            <div css={clipagemContentItem}>
              <h4>02/06</h4>
              <h3>Entrevista com Lucas Montarroios e Luis Attroch Tema: especialistas alertam sobre segurança e  perigos na internet</h3>
              <a href='https://portalcorreio.com.br/especialistas-alertam-sobre-seguranca-e-perigos-da-internet/' target='_blank'>Ver</a>
            </div>
          </Panel>
        </Collapse>
      </Div>
    </Column>
    <Column
      mb={[16]}
    >
      <Div
        maxWidth={['100%', '100%', 422]}
      >
        <Collapse accordion>
          <Panel header='JULHO' css={clipagemItem}>
            <div css={clipagemContentItem}>
              <h4>04/07</h4>
              <h3>Nota na coluna do Fábio Cardoso</h3>
              <a href='https://correiodaparaiba.com.br/colunas/acesso-obrigatorio/' target='_blank'>Correio da Paraíba</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>11/07</h4>
              <h3>Nota sobre o Press Day na Coluna do Meio</h3>
              <a href='https://auniao.pb.gov.br/servicos/arquivo-digital/jornal-a-uniao/2019/julho/jornal-em-pdf-11-07-19.pdf/view' target='_blank'>A União</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>14/07</h4>
              <h3>Entrevista com Filipe Mendes na Coluna do Meio</h3>
              <a href='https://auniao.pb.gov.br/servicos/arquivo-digital/jornal-a-uniao/2019/julho/a-uniao-14-07-19.pdf/view' target='_blank'>A União</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>16/07</h4>
              <h3>Matéria sobre segurança de dados</h3>
              <a href='https://drive.google.com/file/d/1eiVJwDSxQisv1dznTawFan9Q_pw7XV3z/view' target='_blank'>Correio da Paraíba</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>26/07</h4>
              <h3>Entrevista com Analista de Reeds Luís Attroch, na CBN João pessoa Tema: vazamento de conversas no telegram e proteção de dados em aplicativos de mensagens</h3>
              <a href='https://www.instagram.com/tv/B0Yh186nCTR/?hl=pt-br' target='_blank'>Ver</a>
            </div>
          </Panel>
          <Panel header='SETEMBRO' css={clipagemItem}>
            <div css={clipagemContentItem}>
              <h4>23/09</h4>
              <h3>Lidiane Oliveira no Gerardo Rabello</h3>
              <a href='https://gerardorabello.com.br/muito-mais-tv-manaira-23-09-2019/' target='_blank'>Ver</a>
            </div>
          </Panel>
          <Panel header='OUTUBRO' css={clipagemItem}>
            <div css={clipagemContentItem}>
              <h4>04/10</h4>
              <h3>Segurança nos aplicativos de mensagens, com Clauderson</h3>
              <a href='https://www.youtube.com/watch?v=PUDyRBA7zUA' target='_blank'>TV Tambaú</a>
            </div>
            <div css={clipagemContentItem}>
              <h4>13/10</h4>
              <h3>Amistoso Brasil x Nigeria com logo da HostDime em destaque no gol de Casemiro</h3>
              <a href='https://www.cbf.com.br/cbf-tv/confira-as-imagens-do-empate-em-1-a-1-entre-brasil-e-nigeria' target='_blank'>Ver</a>
            </div>
          </Panel>
          <Panel header='NOVEMBRO' css={clipagemItem}>
            <div css={clipagemContentItem}>
              <h3>Case de inovação da Prefeitura Municipal de João Pessoa</h3>
              <a href='https://www.youtube.com/watch?v=gW3G_OMH8Ik' target='_blank'>Ver</a>
            </div>
          </Panel>
        </Collapse>        
      </Div>
    </Column>
  </Fragment>
)

export default App